import Vue from 'vue'
import groupBy from 'lodash/groupBy'
import formatPrice from '@/v-shop/helpers/format-price'

// mixinFor -- 'page' or 'card'
export default (mixinFor = 'card') => ({
	provide() {
		return {
			manager: this,
		}
	},
	data() {
		return {
			selectedVariantId: null,
		}
	},
	computed: {
		limitedTimeOffer() {
			let offer = this.$store.get('shop/limitedTimeOffer')
			if (this.timerProduct)
				return offer?.find((item) => this.product.collectionsIds.find((el) => item.collectionId == el))
			else return offer
		},
		productOffers() {
			return this.limitedTimeOffer?.filter((offer) => this.inCollection(offer.collectionId))
		},
		hasLimitedTimeOffer() {
			return !!this.productOffers?.length
		},
		limitedTimeOfferDate() {
			const date = this.limitedTimeOffer?.untilDate

			const fecha = new Date(date).toLocaleDateString('es-AR', {
				day: 'numeric',
				month: 'long',
				timeZone: 'UTC',
			})
			if (this.timerProduct) return `La oferta termina el ${fecha}`
			else return `Hasta el ${fecha}`
		},
		textTags() {
			return this.$srv('textTags', [])?.filter((tag) => this.inCollection(tag.collectionId))
		},
		hasTextTag() {
			return !!this.textTags?.length
		},
		eventIcons() {
			let eventIcon = this.$srv('collectionEventIcon', [])
			return eventIcon?.filter((icon) => this.inCollection(icon.collectionId))
		},
		hasEventIcon() {
			return !!this.eventIcons?.length
		},
		productRoute() {
			let route = {
				name: 'product',
				params: { urlName: this.product.urlName, id: this.product.id },
			}
			if (!this.product.hasUniqueVariant) {
				route.query = { variant: this.selectedVariant.id }
			}
			return route
		},
		selectedVariant() {
			if (!this.selectedVariantId) return this.product.variants.find((variant) => variant.forDisplay)
			return this.product.variants.find((variant) => variant.id == this.selectedVariantId)
		},
		prevPrice() {
			return this.selectedVariant.pvPrice.prevPrice
		},
		price() {
			return this.selectedVariant.pvPrice.price
		},
		discountPct() {
			return Vue.filter('discountPct')(this.selectedVariant.pvPrice.discountPct)
		},
		currency() {
			return this.selectedVariant.pvPrice.priceConfig.currency.sign
		},
		mainImage() {
			let img = this.product.images[0].square
			img.alt = this.product.name
			return img
		},
		secondImage() {
			let img = this.product.images[1]?.square
			if (img) img.alt = this.product.name
			return img
		},
		brand() {
			return this.product.brand?.name
		},
		financingTagHtml() {
			return this.$srv('financingTags', [])
				.map(
					(tag) =>
						this.inCollection(tag.collectionId) &&
						this.formatFinancingTagHtml(tag.text, tag.installments)
				)
				.filter(Boolean)
		},
		discountsTagsTexts() {
			return this.$srv('discountTags', [])
				.map(
					(tag) =>
						this.inCollection(tag.collectionId) && this.formatDiscountTagText(tag.text, tag.discount)
				)
				.filter(Boolean)
		},
		tags() {
			return this.$srv('productTags', [])
				.map((tag) => {
					if (
						!tag.content ||
						(tag.show != 'both' && tag.show != mixinFor) ||
						(tag.collectionId && !this.inCollection(tag.collectionId))
					)
						return

					const style = { backgroundColor: tag.bgColor, color: tag.textColor }
					return { html: this.evaluateTag(tag.content), style }
				})
				.filter(Boolean)
		},

		outOfStock() {
			return this.isOutOfStock(this.selectedVariant)
		},
		isCustomizable() {
			return this.product.info.customizationKey
		},
		btnText() {
			const config = this.$srv('productActionBtn', {})
			switch (this.selectedVariant.stock.availability) {
				case 'PreSale':
					return config.preSale || 'Preventa'
				case 'OutOfStock':
					return config.outOfStock || 'Sin stock'
				case 'PreOrder':
					return config.preOrder || 'Comprar'
				case 'InStock':
				default:
					return config.inStock || 'Comprar'
			}
		},
	},
	methods: {
		getTagAttrs(tag) {
			const matchedAttr = this.product.attrs.filter((attr) => attr.attrKey.k == tag.attrKeyId)

			if (!matchedAttr.length) return

			let group = groupBy(matchedAttr, (t) => t.attrKeyId)
			let keys = Object.keys(group)
			let resultantTags
			if (keys?.length) {
				resultantTags = keys.map((k) => {
					for (const g of group[k]) {
						return g
					}
				})
				if (resultantTags?.length < 3) {
					keys.forEach((key) => {
						let attribute = resultantTags.find((r) => r.attrKeyId == key)
						let result = group[key].find((g) => g.v != attribute.v)
						if (result) resultantTags.push(result)
					})
				}
				return resultantTags.slice(0, 4)
			}
			return resultantTags.slice(0, 4)
		},
		formatProductTag(tag) {
			if (tag.type == 'text') return tag.text
			else if (tag.type == 'financing')
				return this.formatFinancingTagHtml(tag.financingText, tag.installments)
			else if (tag.type == 'text') return this.formatDiscountTagText(tag.discountText, tag.discount)
		},
		formatFinancingTagHtml(text, installments) {
			let price = this.selectedVariant.pvPrice.price
			let currency = this.selectedVariant.pvPrice.priceConfig.currency.sign
			if (text.includes('[installments]')) text = text.split('[installments]').join(installments)
			if (text.includes('[amount]'))
				text = text
					.split('[amount]')
					.join(
						'<span class="financing-tag-price"> ' +
							formatPrice(price / installments, { currency }) +
							' </span>'
					)

			return text
		},
		formatDiscountTagText(text, discount) {
			let price = this.selectedVariant.pvPrice.price
			let currency = this.selectedVariant.pvPrice.priceConfig.currency.sign
			let percentage = 100 - discount

			if (text.includes('[discount]') || text.includes('[amount]')) {
				text = text.split('[amount]').join(formatPrice((price / 100) * percentage, { currency }))
				text = text.split('[discount]').join(discount)
				return text
			}
			return text
		},
		inCollection(collectionId) {
			return !!this.product.collectionsIds?.some((id) => id == collectionId)
		},
		evaluateTag(rawTag) {
			const doEval = (fnCode) => {
				let fnVars = {
					price: this.price,
					prevPrice: this.prevPrice,
					money: (amount) =>
						formatPrice(amount, {
							currency: this.selectedVariant.pvPrice.priceConfig.currency.sign,
						}),
					product: this.product,
					variant: this.selectedVariant,
				}

				let fnStr = `
					let { ${Object.keys(fnVars).join(', ')} } = fnVars
					return ${fnCode}
				`
				let func = new Function('fnVars', fnStr)
				return func(fnVars)
			}
			try {
				return rawTag.replace(/{{(.*?)}}/g, (_, expression) => doEval(expression))
			} catch (error) {
				return
			}
		},
		isOutOfStock(variant) {
			return variant.stock.availability == 'OutOfStock'
		},
	},
})

